import ModuleGrid from '~/components/modules/ModuleGrid';

const SplitBlock = ({value}) => {
  return (
    <>
      {/*
<ModuleGrid items={value.modules} />
      */}
    </>
  );
};

export default SplitBlock;
