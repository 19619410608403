import clsx from 'clsx';
import LinkButton from '~/components/elements/LinkButton';
import type {SanityModuleCallout} from '~/lib/sanity';
import {useColorTheme} from '~/lib/theme';
import {useMatches} from '@remix-run/react';
import {DEFAULT_LOCALE} from '~/lib/utils';

type Props = {
  module: SanityModuleCallout;
};

export default function CalloutModule({module}: Props) {
  const colorTheme = useColorTheme();

  const bgClass = '';

  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;
  const lang = selectedLocale.language;

  let title = module.text;
  if (lang === 'EN') {
    title = module.textEn;
  }

  return (
    <div
      className={`mr-auto flex flex-col items-start pb-2 pt-2 ${bgClass}`}
      style={{color: colorTheme?.text}}
    >
      {/* Text */}
      <div
        className={clsx(
          'max-w-[60rem] text-2xl', //
          'md:text-4xl',
        )}
      >
        {title}
      </div>

      {lang === 'DE' ? (
        <>
          {/* Link */}
          {module.link && (
            <div className="mt-4">
              <LinkButton
                backgroundColor={colorTheme?.text}
                link={module.link}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {/* Link */}
          {module.linkEn && (
            <div className="mt-4">
              <LinkButton
                backgroundColor={colorTheme?.text}
                link={module.linkEn}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
