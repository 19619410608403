import type {PortableTextBlock} from '@portabletext/types';
import clsx from 'clsx';

import ProductModule from '~/components/modules/Product';
import type {SanityModuleProducts} from '~/lib/sanity';

type Props = {
  value: PortableTextBlock & SanityModuleProducts;
};

export default function ProductsBlock({value}: Props) {
  const multipleProducts = value.modules.length > 1;

  const products = value.modules.length;
  let layout = value.layout;

  return (
    <>
      {layout === 'responsive' ? (
        <>
          <div
            className={clsx(
              'first:mt-0 last:mb-0',
              'my-8 grid grid-cols-1 gap-3',
              products === 2
                ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2'
                : 'sm:grid-cols-1 md:grid-cols-1',
              products === 3
                ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'
                : 'sm:grid-cols-1 md:grid-cols-1',
              products === 4
                ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
                : 'sm:grid-cols-1 md:grid-cols-1',
              products === 5
                ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5'
                : 'sm:grid-cols-1 md:grid-cols-1',
              products === 6
                ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6'
                : 'sm:grid-cols-1 md:grid-cols-1',
            )}
          >
            {value?.modules?.map((module) => (
              <ProductModule
                imageAspectClassName="aspect-[220/220]"
                key={module._key}
                layout={value.layout}
                module={module}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            className={clsx(
              'first:mt-0 last:mb-0',
              'my-8 grid grid-cols-1 gap-3',
              multipleProducts ? 'md:grid-cols-2' : 'md:grid-cols-1',
            )}
          >
            {value?.modules?.map((module) => (
              <ProductModule
                imageAspectClassName="aspect-[220/220]"
                key={module._key}
                layout={value.layout}
                module={module}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
