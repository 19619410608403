import {useMatches} from '@remix-run/react';
import {DEFAULT_LOCALE} from '~/lib/utils';

type Props = {};

export default function CopyBlock({value}) {
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;
  const lang = selectedLocale.language;

  let title = '';
  let copytext = '';

  if (lang === 'DE') {
    title = value.title?.de;
    copytext = value.text?.de;
  }
  if (lang === 'EN') {
    title = value.title?.en;
    copytext = value.text?.en;
  }

  return (
    <>
      <div className={'mx-auto w-full max-w-[1400px]'}>
        <h2 className="mb-4 mt-16 text-xl font-bold first:mt-0 last:mb-0">
          {title}
        </h2>
        <p className="relative my-4 leading-paragraph first:mt-0 last:mb-0">
          {copytext}
        </p>
      </div>
    </>
  );
}
